import React, { useEffect } from "react"

// components
import HeaderBtn from "../layout/headerButtons"

// gifs
import cfdGIF from "../../gifs/cfd.gif"

const Landing = ({ lang, langType }) => {
  const { title, body } = lang

  useEffect(() => {}, [])

  return (
    <section className="desktop:mt-32 mobile:mt-20 pt-10">
      <div className="max-w-1024 px-2x mx-auto">
        <h1 className="heading">{title}</h1>
        <div className="flex mobile:flex-col-reverse justify-between h-full w-full mt-6">
          <div className="mobile:w-full mobile:mt-6 desktop:max-w-500 mr-4">
            <p className="body">{body}</p>
            <HeaderBtn
              blk={true}
              linkTo={
                langType === "en"
                  ? "/cfd-modeling/#more-info"
                  : "/es/cfd-modeling/#more-info"
              }
            />
          </div>
          <img
            className="desktop:max-w-450 m-0"
            src={cfdGIF}
            alt="CFD Air flow"
          />
        </div>
      </div>
    </section>
  )
}

export default Landing
