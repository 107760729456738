import React, { useEffect } from "react"
import Img from "gatsby-image"

const WhatWeDo = ({ isoSRC, thermalSRC, contaminantsSRC, lang }) => {
  const { title, subTitles, subTexts } = lang

  useEffect(() => {}, [])

  return (
    <section className="w-full bg-trueBlue pt-16 pb-24 mt-16">
      <div className="max-w-1024 px-2x mx-auto ">
        <h1 className="font-heading text-white">{title}</h1>
        <div className="flex mobile:flex-col justify-between">
          <div className="bg-gray-100 p-10 pb-20 desktop:max-w-500 desktop:mr-6">
            <div className="mb-8">
              <h3 className="heading text-xl">{subTitles[0]}</h3>
              <p className="body text-sm mt-4">{subTexts[0]}</p>

              <p className="body text-sm mt-4">{subTexts[1]}</p>
              <div className="bg-center bg-cover max-w-450 h-40 relative desktop:hidden mt-6">
                <Img className="h-full w-full absolute" fluid={isoSRC} />
              </div>
            </div>
            <div className="mb-8">
              <h3 className="heading text-xl">{subTitles[1]}</h3>
              <p className="body text-sm mt-4">{subTexts[2]}</p>
              <div className="bg-center bg-cover max-w-450 h-40 relative desktop:hidden mt-6">
                <Img className="h-full w-full absolute" fluid={thermalSRC} />
              </div>
            </div>
            <div>
              <h3 className="heading text-xl">{subTitles[2]}</h3>
              <p className="body text-sm mt-4">{subTexts[3]}</p>
              <div className="bg-center bg-cover max-w-450 h-40 relative desktop:hidden mt-6">
                <Img
                  className="h-full w-full absolute"
                  fluid={contaminantsSRC}
                />
              </div>
            </div>
          </div>
          <div className="mobile:hidden w-450 flex flex-col justify-between">
            <div className="bg-center bg-cover w-full h-40 relative">
              <Img className="h-full w-full absolute" fluid={isoSRC} />
            </div>
            <div className="bg-center bg-cover w-full h-40 relative">
              <Img className="h-full w-full absolute" fluid={thermalSRC} />
            </div>
            <div className="bg-center bg-cover w-full h-40 relative">
              <Img className="h-full w-full absolute" fluid={contaminantsSRC} />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default WhatWeDo
